import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  user: {},
};

const changeState = (state = initialState, { type, ...rest }) => {

  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "set_user":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

