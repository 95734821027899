// export const WEB_API = 'https://localhost:7210/api/';
export const WEB_FRONTEND = 'https://test-e-dp.scgp.co.th/login';
export const WEB_LOGOUT = 'https://test-e-dp.scgp.co.th/logout';
export const WEB_REPORT = 'https://edp-lms-appservice-dev.azurewebsites.net/';
export const WEB_BLOB_PATH = 'https://edpblobdev.blob.core.windows.net/wwwroot/';
export const WEB_API = 'https://edp-backoffice-api-appservice-dev.azurewebsites.net/api/';
// export const WEB_API = 'https://localhost:7210/api/';
export const REACT_APP_URL = 'https://edp-backoffice-appservice-dev.azurewebsites.net/';
// export const REACT_APP_URL = 'http://localhost:3000/';
export const VERSION_RELEASE = 'e-DP version 0.0.1.2';
export const secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
export const algorithm = 'aes-256-ctr';
export const randomBytes = 16;
